import React from "react"

export const FooterBrand = ({ mobile, absolute, children }) => {
  if (mobile === true) {
    return (
      <div>
        <div className={`floating ${mobile && `mobile`} ${absolute && `absolute`}`}>
          <strong className="text-white mr-2">Fostive Visual</strong>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`d-none d-lg-block`}>
        <div className="floating right-bottom d-flex align-items-center">
          <span className="text-white mr-2">Fostive Visual</span>
          <div className="custom-rectangle"></div>
        </div>
      </div>
    )
  }
}
