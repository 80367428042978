const replaceUnicode = text =>
  text &&
  text
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/&#8211;/gi, "-")
    .replace(/&#038;/gi, "&")
    .replace(/&#8216;/gi, "'")
    .replace(/&#8221;/gi, '"')
    .replace(/&#8217;/gi, "'")
    .replace(/&amp;/gi, "&")
    .replace(/&nbsp;/gi, ",")

const getYoutubeId = url => {
  return url?.includes("watch?v=")
    ? url?.split("watch?v=")[1]
    : url?.substring(url?.lastIndexOf("/") + 1)
}

export { replaceUnicode, getYoutubeId }
